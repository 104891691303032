import { render, staticRenderFns } from "./open.vue?vue&type=template&id=25c5c948&scoped=true"
import script from "./open.vue?vue&type=script&lang=js"
export * from "./open.vue?vue&type=script&lang=js"
import style0 from "./open.vue?vue&type=style&index=0&id=25c5c948&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25c5c948",
  null
  
)

export default component.exports